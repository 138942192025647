html, body {
  max-width: 100%;
  height: 100%;
  overflow-x: hidden;
}

main {
  margin-top: 0 !important;
  padding-top: 1em !important;
}

footer {
  opacity: .8;
  margin-bottom: .5em;
  font-size: .8em;
  margin-left: 1em !important;
  padding: 0 !important;
}

.center {
  text-align: center;
}

#logo {
  max-height: 40px;
  margin-left: 1em;
  margin-right: -1em;
}

#page-title {
  margin-left: -2em;
}

@media (max-width: 576px) {
  #logo-container {
    display: none;
  }

  #page-title {
    margin-left: 1em;
  }
}

#parameter {
  cursor: pointer;
  margin-right: 1em;
  font-size: 1.5em;
}

#parameter:hover {
  opacity: .8;
}

#parameter-switch {
  margin-top: 2em;
  margin-bottom: 2em;
}

#parameter-switch div {
  margin-bottom: 1em;
}

.close {
  cursor: pointer;
}

.close:hover {
  opacity: .8;
}

#parameter-reset-button {
  cursor: pointer;
  color: var(--primary);
}

#parameter-reset-button:hover {
  color: var(--primary-hover);
  text-decoration: underline;
}

@media (min-width: 992px) {
  #parameter-reset {
    max-width: 75%;
    margin-top: -4em;
  }
}

#title {
  margin: .5em;
}

#title h3 {
  margin: 0;
}

#profil {
  max-width: 15%;
  border-radius: 50%;
}

@media (max-width: 992px) {
  #profil {
    max-width: 20%;
  }
}

@media (max-width: 768px) {
  #profil {
    max-width: 25%;
  }
}

@media (max-width: 576px) {
  #profil {
    max-width: 30%;
  }

  #title {
    margin: 1em;
  }
}

#presentation-card {
  width: 70%;
  margin: auto;
}

#presentation-card article {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 2.8em;
  padding-bottom: 2.8em;
  position: relative;
}

#konami-helper {
  opacity: .4;
  font-size: .5em;
  position: absolute;
  bottom: 8px;
  left: 8px;
}

#links {
  margin-bottom: 0;
}

.link {
  text-align: center;
  margin: 1em;
  padding: 0;
}

.link a {
  width: 80%;
  max-height: 70px;
  max-width: 800px;
}

.link.animation a {
  transform-style: preserve-3d;
  transform: perspective(1000px);
}

.link.animation a .content {
  color: initial;
  transform: translateZ(20px);
}

.link.animation[data-color="amber"] a:hover .content, .link.animation[data-color="lime"] a:hover .content, .link.animation[data-color="yellow"] a:hover .content {
  color: #000;
}

.link.no-animation a, .link.no-animation a .content {
  color: initial;
}

.link.no-animation a:hover {
  border-color: initial;
}

.link.no-animation a:hover .content {
  color: var(--primary-hover);
}

.animation a {
  background-repeat: repeat-x;
  background-image: var(--link-button-background);
  transition: background-position .2s;
}

.animation a:hover {
  background-position: 100% 55%;
}

#legal {
  text-align: center;
  opacity: .6;
  font-size: .8em;
}

#legal span {
  cursor: pointer;
  color: var(--primary);
}

#legal span:hover {
  color: var(--primary-hover);
  text-decoration: underline;
}

#legal-dialog article {
  max-width: 75vw;
  max-height: 90vh;
  overflow-y: hidden;
}

#legal-dialog article header {
  margin-bottom: 0;
}

#legal-text {
  max-height: 60vh;
  margin-bottom: 1em;
  padding: 0 .5em;
  overflow-y: scroll;
}

/*# sourceMappingURL=index.9e4f17dd.css.map */
